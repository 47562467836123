<template>
  <section class="pa-3">
    <modal-loading :is-loading="saving" message="表示順を保存しています..." />
    <modal-confirmation
      :state-modal="isModal"
      title="表示順を保存してもよろしいですか？"
      color="primary"
      label="保存する"
      @hideModal="toggleModal"
      @apply="save"
    />
    <div class="d-flex align-start flex-wrap mb-10">
      <h2 class="mb-6 mr-8">カテゴリ一覧</h2>
      <section class="d-flex align-center">
        <v-btn
          large
          rounded
          color="#2196F3"
          :to="{ name: 'categories_create' }"
          class="mr-5 white--text"
          :disabled="categories.length >= 5"
        >
          <v-icon dark class="mr-3"> mdi-shape-outline </v-icon>
          新規登録
        </v-btn>
        <p v-if="categories.length >= 5" class="ma-0">
          カテゴリの作成は５つまでです
        </p>
      </section>
    </div>

    <div class="d-flex">
      <v-switch
        v-model="sortableState"
        :disabled="categories.length === 0"
        label="表示順の編集"
      />
      <v-btn
        :disabled="!sortableState || categories.length === 0"
        class="mt-3 ml-4 grey lighten-3"
        @click="toggleModal"
      >
        保存
      </v-btn>
    </div>

    <section v-if="categories.length !== 0">
      <v-data-table
        :headers="headers"
        :items="categories"
        no-results-text="データがありません。"
        hide-default-footer
      >
        <template v-slot:item.id>
          <v-icon v-if="sortableState" color="#888"> mdi-drag </v-icon>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            v-if="!sortableState"
            :to="{
              name: 'categories_edit',
              params: {
                id: item.id,
              },
            }"
          >
            {{ item.name }}
          </router-link>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
      </v-data-table>
    </section>

    <!-- categoriesがない場合、ロード中用 -->
    <section v-if="categories.length === 0">
      <v-data-table
        :headers="headers"
        :items="[]"
        :loading="loading"
        :loading-text="'ロード中...'"
        :no-data-text="'データがありません。'"
        hide-default-footer
      />
    </section>
  </section>
</template>

<script>
import { getData, updateData } from "@/axios";
import Sortable from "sortablejs";
import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";

export default {
  name: "Categories",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      loading: true,
      headers: [
        { text: "", align: "left", sortable: false, value: "id", width: "5%" },
        { text: "表示順", align: "left", sortable: true, value: "order" },
        { text: "カテゴリ名", align: "left", sortable: false, value: "name" },
        { text: "URL用文字列", align: "left", sortable: false, value: "slug" },
      ],
      categories: [],
      sortablejs: null,
      sortableState: false,
      isModal: false,
      saving: false,
      moveConfirm: false,
    };
  },

  watch: {
    sortableState() {
      this.$el
        .querySelector(".v-data-table tbody")
        .classList.toggle("sortable");
      this.sortablejs &&
        this.sortablejs.option("disabled", !this.sortableState);
    },
  },

  mounted() {
    const table = this.$el.querySelector(".v-data-table tbody");
    const self = this;
    this.sortablejs = Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        self.moveConfirm = true;
        const rowSelected = self.categories.splice(oldIndex, 1)[0];
        self.categories.splice(newIndex, 0, rowSelected);
        self.categories.forEach((category, i) => {
          category.order = i + 1;
        });
      },
    });
    this.sortablejs && this.sortablejs.option("disabled", !this.sortableState);
  },

  created() {
    this.getCategories();
  },

  beforeRouteLeave(to, from, next) {
    if (this.moveConfirm) {
      const answer = window.confirm(
        "このページから移動すると表示順の編集内容が破棄されます。"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    getCategories() {
      this.loading = true;
      getData("categories")
        .then((res) => {
          this.categories = res.data;
          this.loading = false;
          if (this.saving) {
            this.saving = false;
            this.setSuccessMessage();
          }
        })
        .catch(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    save() {
      this.isModal = false;
      this.saving = true;
      const categories = this.categories.slice();
      let isUpdating = false;
      let timer = setInterval(() => {
        if (!isUpdating && categories.length !== 0) {
          isUpdating = true;
          const data = categories.shift();
          const formData = new FormData();
          formData.append("order", data.order);
          formData.append("name", data.name);
          formData.append("slug", data.slug);
          formData.append("photos", data.photos);
          formData.append("pin_color", data.settings.pin_color);
          formData.append("pin_image", data.settings.pin_image);
          updateData(`categories/${data.id}`, formData)
            .then(() => {
              isUpdating = false;
            })
            .catch(() => {
              clearInterval(timer);
              this.saving = false;
            });
        } else if (!isUpdating && categories.length === 0) {
          clearInterval(timer);
          this.sortableState = false;
          this.moveConfirm = false;
          this.getCategories();
        }
      }, 1000);
    },
    setSuccessMessage() {
      this.$store.dispatch("snackbar/setSnackbar", {
        message: "ページの表示順を編集しました。",
        color: "success",
        timeout: 2000,
      });
    },
  },
};
</script>

<style>
tbody.sortable {
  color: #888;
  cursor: move;
}
</style>
